<template>
  <v-container fluid>
    <v-subheader>
      {{$vuetify.lang.t('$vuetify.home.welcome')}} {{companyName}}
    </v-subheader>
<v-divider/>
    <my-recent-qr-codes />
 </v-container>
</template>

<script>
const MyRecentQrCodes = () =>
  import("@/components/home/MyRecentQrCodes.vue");

  export default {
    data: () => ({
      companyName: process.env.VUE_APP_TITLE
    }),
    components: {
        MyRecentQrCodes
    },
    methods:{
      change(lang){
        localStorage.setItem("locale", lang);
        this.$i18n.local = lang;
      }
    }

  }
</script>
